import {Notification} from 'types/notifications'

export const login = "login"
export const deviceCheck = "device-check"
export const splash = "splash"
export const welcome = "welcome"

export const getProgramPath = (program: string) => `/${program.toLowerCase()}`
export const getIndexPath = (program: string) => `${getProgramPath(program)}/index.html`
export const getLoginPath = (program: string) => `${getProgramPath(program)}/${login}`
export const getCompatibilityCheckPath = (program: string) => `${getProgramPath(program)}/${deviceCheck}`
export const getSplashPath = (program: string) => `${getProgramPath(program)}/${splash}`
export const getWelcomePath = (program: string) => `${getProgramPath(program)}/${welcome}`

/** ---- COURSES ----- **/
export const addCourses = "add-courses"
export const myCourses = "my-courses"
export const summary = "summary"
export const chapters = "chapters"
export const sections = "sections"

export const getAddCoursePath = (program: string) => `${getProgramPath(program)}/${addCourses}`
export const getMyCoursesPath = (program: string) => `${getProgramPath(program)}/${myCourses}`
export const getCourseSummaryPath = (program: string, courseSlug?: string) =>
  `${getMyCoursesPath(program)}/${courseSlug || ":courseSlug"}/${summary}`
export const getCoursePath = (program: string, courseSlug?: string) =>
  `${getMyCoursesPath(program)}/${courseSlug || ":courseSlug"}/${chapters}`
export const getChapterPath = (program: string, courseSlug: string, chapterId: string, sectionId?: string) =>
  `${getCoursePath(program, courseSlug)}/${chapterId}/${sections}/${sectionId || ":id"}`

/** ---- TRANSACTION / IMPACT ----- **/
export const transactions = "transactions"
export const transactionsCollectIdParam = "9999"
export const surveys = "surveys"
export const surveyLinkParam = "collect-id"
export const fillsSurvey = "fillsSurvey"
export const impact = "impact"
export const questions = "questions"

export const getTransactionsPath = (program: string, collectId?: string) => `${getProgramPath(program)}/${transactions}/${collectId || ":collectId"}`
export const getTransactionPath = (program: string, collectId: string, surveyId: string, questionId?: string) =>
  `${getTransactionsPath(program, collectId)}/${surveys}/${surveyId}/${questions}/${questionId}`
export const getImpactPath = (program: string, collectId?: string) => `${getProgramPath(program)}/${impact}/${collectId || ":collectId"}`
export const getSurveyPath = (program: string, collectId: string, surveyId: string, questionId?: string) =>
  `${getImpactPath(program, collectId)}/${surveys}/${surveyId}/${questions}/${questionId}`

/** ---- FEATURES HUB ----- **/
export const settings = "settings"
export const tutorial = "tutorial"
export const appTutorial = "sutti-app-tutorial"
export const profile = "profile"
export const copyright = "copyright"
export const services = "services"
export const messages = "messages"
export const termsAndConditions = "terms-and-conditions"

export const getSettingsPath = (program: string) => `${getProgramPath(program)}/${settings}`
export const getAppTutorialPath = (program: string) => `${getProgramPath(program)}/${tutorial}`
export const getProfilePath = (program: string) => `${getProgramPath(program)}/${profile}`
export const getProfileEditPath = (program: string) => `${getProgramPath(program)}/${profile}/edit`
export const getCopyrightPath = (program: string) => `${getProgramPath(program)}/${copyright}`
export const getServicesPath = (program: string) => `${getProgramPath(program)}/${services}`
export const getMessagesPath = (program: string, message?: Notification) => `${getProgramPath(program)}/${messages}`
export const getSingleMessagePath = (program: string, id: string) => `${getProgramPath(program)}/${messages}/${id}`
export const getMyTermsAndConditionsPath = (program: string) => `${getProgramPath(program)}/${termsAndConditions}`
export const getTermsAndConditionsPath = (program: string, courseSlug?: string) =>
  `${getMyTermsAndConditionsPath(program)}/${courseSlug || ":courseSlug"}/${chapters}`
export const getTermsAndConditionsAgreementPath = (program: string, courseSlug?: string, chapterId?: string, sectionId?: string) =>
  `${getTermsAndConditionsPath(program, courseSlug)}/${chapterId || ":chapterId"}/${sections}/${sectionId || ":id"}`
