import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { selectLanguage, selectLanguageObject } from 'pages/Login/LoginSlice'
import { NotificationTypes } from "constants/notifications"
import { PriceMessageBody } from "types/notifications"
import { Speech } from "components"
import { StyleProps } from "types/styles"
import useStyles from './MessageBody.styles'

export interface MessageBodyProps {
  type: string,
  body: string,
  style?: StyleProps,
}

const MessageBody: React.FC<MessageBodyProps> = (props) => {
  const {
    type,
    body,
    style={},
  } = props

  const dictionary = useSelector(selectLanguageObject)
  const language = useSelector(selectLanguage)

  const classes = useStyles(style)

  const [headers, setHeaders] = useState([''])
  const [content, setContent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    let content: JSX.Element;

    switch (type) {
      case NotificationTypes.priceChange:
        setHeaders([`${dictionary.product}`, `${dictionary.price} (${JSON.parse(body)[0].price.currency})`])
        content = JSON.parse(body).map((messageBody: PriceMessageBody, index: number) => {
            return (
              <div className={classes.mainText} key={index}>
                <div className={classes.text}>
                  <Speech text={messageBody.product}>
                     {messageBody.product}
                  </Speech>
                </div>
                <div className={`${classes.contentBoldText} ${classes.boldText}`}>
                  <Speech text={Intl.NumberFormat(language, { maximumFractionDigits: 6 }).format(parseFloat(messageBody.price.value))}>
                    {Intl.NumberFormat(language, { maximumFractionDigits: 6 }).format(parseFloat(messageBody.price.value))}
                  </Speech>
                </div>
              </div>
            )
          }
        )
        setContent(content)
        break
      case NotificationTypes.simpleMessage:
        setHeaders([])
        
        content = (
          <div className={classes.mainText}>
            <div className={classes.text}>
              <Speech text={body}>
                {JSON.parse(body)}
              </Speech>
            </div>
          </div>
        )
        setContent(content)
        break
      default:
        setHeaders([])
    }
  }, [body, classes, dictionary, language, type])

  return (
    <div>
      <div className={classes.mainText}>
        { headers.map((header, index) => {
            return (
              <div key={index} className={`${classes.header} ${classes.boldText}`}>
                <Speech text={header}>
                  { header }
                </Speech>
              </div>
            )
        })}
      </div>

      { content }
    </div>
  )
}

export default MessageBody