import { Typography } from '@material-ui/core'
import React from 'react'
import clsx from "clsx"
import { Speech } from 'components'
import useStyles from "./Text_Styles"
import { useSmallScreen } from 'hooks'

interface TextProps {
    children: string
    variant?: "title" | "description"
    className?: string
}

//This component is used to set text on the pages
const Text: React.FC<TextProps> = ({ children, variant = "description", className }) => {
    const classes = useStyles()
    const isSmallScreen = useSmallScreen()
    const textClass = isSmallScreen
        ? clsx(classes.text, classes.smallText)
        : classes.text

    return (
        <Speech text={children}>
            <Typography className={variant === "title"
                ? clsx(textClass, classes.title, className)
                : clsx(textClass, className)}>
                {children}
            </Typography>
        </Speech>
    )
}

export default Text
