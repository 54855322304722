import React, { createContext, useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ProgramConfig } from '../types'
import { fetchPrograms, selectPrograms, selectIsLoading } from './ProgramsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppThunkDispatch } from '../redux/store'
import { useServerErrorHandler } from '../hooks'
import { selectLanguageObject } from "pages/Login/LoginSlice"
import { ErrorComponent, Loader } from 'components'
import { selectOnlineState } from 'pages/Impact/Impact.slice'

/* Only used as typescript non-null default value */
const defaultProgram: ProgramConfig = {
  id: "0",
  name: "default",
  description: "",
  configs: {
    languages: [{"code": "en-GB", "flag": "🇬🇧", "name": "English (UK)"}, {"code": "fr-FR", "flag": "🇫🇷", "name": "French (France)"}, {"code": "id-ID", "flag": "🇮🇩", "name": "Bahasa (Indonesia)"}],
    defaultLanguage: "en-GB",
    modules: [],
    addCourses: "OFF",
    fontFamily: "Sans Serif",
    palette: null,
    loginScreen: {
      enrollmentUrl: "",
      forgotNumberUrl: ""
    },
    splashScreens: [],
    phonePrefix: '',
    welcomeSplashIcon: "",
    logoLarge: "",
    logoSmall: "",
    favicon: ""
  }
}


const ProgramContext = createContext<ProgramConfig>(defaultProgram)
const Program : React.FC<{program: ProgramConfig}> = ({program, children}) => {
  return <ProgramContext.Provider value={program}>
    { /*<div style={{position: 'absolute',top: '5px', left: '5px', zIndex:100}}>{ program.name }</div>*/ }
    { children }
  </ProgramContext.Provider>
}
export const useProgram = () => useContext(ProgramContext)


export const MultiPrograms : React.FC<{}> = ({children}) => {
  const programs = useSelector(selectPrograms);
  const dictionary = useSelector(selectLanguageObject)
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleServerError = useServerErrorHandler();
  const isLoading = useSelector(selectIsLoading);
  const isOnline = useSelector(selectOnlineState)

  useEffect(() => {
    if (isOnline) {
      try {
        handleServerError(dispatch(fetchPrograms({})))
      }
      catch (e)
      {
      console.error("Gracefully catching an exception while fetching programs to allow offline mode to work", e);
      }
    } 
  }, [dispatch, handleServerError, isOnline])

  if (isOnline && isLoading) {
    return <Loader/>
  }
  
  if (programs.length === 0)
  {
    return <ErrorComponent message={dictionary.serverUnavailable} />
  }
  else
  {
    return <Switch>
      { programs && programs.map((program) =>
          <Route key={program.id} path={`/${program.name.toLowerCase()}`} >
            <Program program={program}>{ children }</Program>
          </Route>
      )}
      { programs?.length > 0 && <Redirect to={`/${programs[0].name.toLowerCase()}`} /> }
    </Switch>
  }

}