import React from "react"
import { useSmallScreen } from "hooks"
import { useSelector } from "react-redux"
import { Avatar, Button as MUIButton } from "@material-ui/core"
import clsx from "clsx"
import { StyleProps } from "types/styles"
import { NotificationTypes } from 'constants/notifications'
import { Notification } from "types/notifications"
import { selectLanguage, selectLanguageObject } from "pages/Login/LoginSlice"
import useStyles from "./EmailCard.styles"
import { Speech } from "components"
export interface EmailCardProps {
  message: Notification,
  onClick: (notification: Notification) => void,
  className?: string,
  style?: StyleProps,
}

const EmailCard: React.FC<EmailCardProps> = (props) => {
  const {
    message,
    onClick,
    className,
    style = { width: "90vw", maxWidth: "60rem" }
  } = props

  const classes = useStyles(style)

  const isSmallScreen = useSmallScreen()

  const dictionary = useSelector(selectLanguageObject)
  const language = useSelector(selectLanguage)

  const buttonClass = isSmallScreen
    ? clsx(classes.button, classes.smallButton)
    : classes.button

  const renderAvatar = (title: string) => {
    const initials = title.split(" ").map(word => word[0]).join("")
    return (
      <Avatar className={classes.avatar}>
        {initials ? initials.toUpperCase() : ""}
      </Avatar>
    )
  }

  const renderTitle = (type: string, title: string) => {
    switch (type) {
      case NotificationTypes.priceChange:
        return `${dictionary.priceNotificationTitle} ${JSON.parse(title).pointOfSale ?? ""}`
      case NotificationTypes.simpleMessage:
        return `${message.body}`
      default:
        return ''
    }
  }

  const renderDate = (date: string) => {
    return date ? new Date(date).toLocaleDateString(language) : ""
  }

  const renderDateForSpeech = (date: string) => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date ? new Date(date).toLocaleDateString(language, options) : ""
  }

  return (
      <div className={classes.container} onClick={() => onClick(message)}>
        <Speech text={message.sender + ", " + renderTitle(message.type, message.title) + ", " + renderDateForSpeech(message.sentDate)} onClick={() => onClick(message)}>
        <MUIButton variant={"contained"}
          color="primary"
          className={clsx(buttonClass, className)}
          disableRipple>
          <div className={classes.sender}>
            {renderAvatar(message.sender)}
          </div>
          <div className={classes.text}>
            <span className={classes.title}>
              {message.sender}
            </span>
            <span className={clsx(classes.description, message.hasBeenRead === 0 ? classes.bold : "")}>
              {renderTitle(message.type, message.title)}
            </span>
          </div>
          <div className={classes.date}>
            {renderDate(message?.sentDate)}
          </div>
        </MUIButton>
        </Speech>
      </div>
  )
}

export default EmailCard