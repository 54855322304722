import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch} from "redux/store";
import { createMuiTheme, Button, Dialog, DialogContent, DialogContentText, DialogActions, MuiThemeProvider } from '@material-ui/core'

import { useGetChapterParams, useServerErrorHandler } from '../hooks'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
import { useProgram } from '../program'
import { useToken } from "cookies";
import { putService } from '../api'
import { PushNotificationsInfos } from "types/userProfile";
import { registerForPushNotifications } from '../utils/registerOptin'

import {
  getCompatibilityCheckPath,
  getLoginPath,
getMyTermsAndConditionsPath,
 getProgramPath,
  getSplashPath, getTermsAndConditionsAgreementPath, getTermsAndConditionsPath,

  getWelcomePath,
} from 'routes'
import Home from "pages";
import LoginPage from "../pages/Login"
import SplashPage from "../pages/SplashPage";
import CompatibilityCheck from '../pages/CompatibilityCheck'
import MyTermsAndConditions from '../pages/MyTermsAndConditions'
import TermsAndConditions from '../pages/TermsAndConditions'
import Agreement from '../pages/Agreement'
import { Snackbar } from "../components";

import { sendOptedInState } from "pages/Profile/ProfileSlice";
import { selectShowSuccessInfo, setShowSuccessInfo, selectOnlineState } from "../pages/Impact/Impact.slice";
import {
  fetchRefreshToken,
  selectFirstAccess,
  selectLanguageObject,
  selectLoggedUser,
  selectLanguage
} from '../pages/Login/LoginSlice'
import {
  selectShowSuccessInfo as selectShowSuccessInfoTransaction,
  setShowSuccessInfo as setShowSuccessInfoTransaction
} from "../pages/Transactions/Transactions.slice";

import useStyles from "./App_Styles"
import { baseUrl } from 'api/config';
import { User } from '../types'
import { speechSynthMsKey, speechSynthMsServer } from 'api/config';
import { fetchMyCourses, selectIsLoading, selectMyCourses } from 'pages/MyCourses/MyCourses.slice';
import PWAinstall from 'components/PWAInstall/PWAinstall';



//This page handle the lauch of the application and the routes but also the detection of device type
function enableAutoTTS(langue : string) {
  if (typeof window === 'undefined') {
    return;
  }

  const language = langue

  const simulateSpeechReact = function () {
    const lecture = new SpeechSynthesisUtterance('hello');
    lecture.volume = 0;
    speechSynthesis.speak(lecture);
    document.removeEventListener('click', simulateSpeech);
  };


  // On first touch, simulate speech first time 
  const simulateSpeechMicrosoft =  async function () {
    let sdk = require("microsoft-cognitiveservices-speech-sdk");
    console.log("ici")
    try {
      const speechConfig = await sdk.SpeechConfig.fromSubscription(speechSynthMsKey, speechSynthMsServer);
      speechConfig.speechSynthesisLanguage =  "si-LK"; 
  
      const synthesizer = new sdk.SpeechSynthesizer(speechConfig, 
          sdk.AudioConfig.fromSpeakerOutput());
      // blank string to disable sound
      await synthesizer.speakTextAsync("", (result:boolean) => {
          if (result) {
              synthesizer.close();
              return result.valueOf;
          }
      },
          (error:boolean) => {
              console.log(error);
              synthesizer.close();
          }
      );
      
    } catch (error) {

      console.log(error)
      
    }

  }

  const simulateSpeech = function () {
    simulateSpeechReact();
    // Only want to simulate speech by Microsoft if in singhalese ot tamil
    if((language === "si-LK") || (language === "ta-LK")){
      console.log("ici")
      simulateSpeechMicrosoft()
    }
    
  }
  document.addEventListener('click', simulateSpeech());
}

const App: React.FC<{}> = () => {
  const program = useProgram()
  const classes = useStyles()
  const { token, tokenPayload, setToken, removeToken, migrateCookie } = useToken()
  const dispatch = useDispatch<AppThunkDispatch>()
  const location = useLocation()
  const history = useHistory()
  const handleServerError = useServerErrorHandler();

  const language = useSelector(selectLanguageObject)
  const langue = useSelector(selectLanguage)
  const loggedUser = useSelector(selectLoggedUser)
  const isOnline = useSelector(selectOnlineState)
  const firstAccess = useSelector(selectFirstAccess)
  const showSuccessInfo = useSelector(selectShowSuccessInfo)
  const showSuccessInfoTransaction = useSelector(selectShowSuccessInfoTransaction)

  const { courseSlug, chapterId } = useGetChapterParams()
  const myCourses = useSelector(selectMyCourses)
  const isLoading = useSelector(selectIsLoading);
  const isMounted = useRef(false);
  const isMountedToken = useRef(false);
  const isMountedMigrationToken = useRef(false);

  useEffect(() => {
    if (loggedUser && isOnline && token && myCourses.length === 0 && !isLoading && isMounted.current === false) {
      handleServerError(dispatch(fetchMyCourses({ token })));
      // Some users might not have any course yet because not set to a cohort, prevent infinite loop
      isMounted.current = true;
    }
  }, [ token, handleServerError, loggedUser, isOnline, dispatch, myCourses, isLoading]);

  const [openUpdate, setOpenUpdate] = useState(false)
  const theme = useMemo(() => createMuiTheme({
    palette: program.configs.palette,
    typography: {
      fontFamily:  program.configs.fontFamily,
    }
  }), [program])

  useEffect(() => {
    document.querySelector('#my-manifest-placeholder')?.setAttribute('href', baseUrl + '/program/' + program.id + '/manifest' + (window.location.href.includes('frontend.live.cascade.sutti.app/') ? '?baseUrl=https://frontend.live.cascade.sutti.app' : ''))
  }, [program])

  useEffect(() => {
    // set apple touch icon depending on the program
    const link = document.getElementById('my-apple-touch-icon')
    if(link){
      link.setAttribute('href', program.configs.logoSmall)
    }   
  }, [program, langue])

  // Redirect SUTTI V1 Program CASCADE to multi-tenant /cascade path
  // do it first time so users get to convert their access token to V2 and keep their data
  // CMS data still needs to be kept running so long as CASCADE program is alive
  useEffect(() => {
    if (window.location.hostname.endsWith('cascade.sutti.app') && !window.location.pathname.startsWith('/cascade/'))
    {
      history.push("/cascade/")
      window.location.reload()  // this step is necessary to ensure tokens are taken into account
    }
  }, [history])

  const handleUpdate = () => {
    window.location.reload();
  }

  const handleClose = () => {
    setOpenUpdate(false)
  }

  useEffect(() => {
    serviceWorkerRegistration.register({}, setOpenUpdate)
  }, [])

  const handleOptedIn = useCallback((optIn: PushNotificationsInfos) =>
    dispatch(sendOptedInState(token, optIn)), [dispatch, token])

  const updatePushSubscription = useCallback(async () => {
    const subscribeInfos = await registerForPushNotifications()

    if(subscribeInfos) {
      handleOptedIn(subscribeInfos)
    }
  }, [handleOptedIn])

  useEffect(() => {
    if(typeof Notification !== 'undefined' && Notification.permission === 'granted') {
      updatePushSubscription()
    }
  }, [updatePushSubscription])

  const updateLastAccess = useCallback(async () => {
    if (loggedUser && isOnline && token && tokenPayload.programId) {
      const url = "user/updateLastAccessDate";
      try {
        await (putService(token, url, { userAgent: navigator.userAgent }));
      } catch (error) {
        console.log("Error occurred when attempting to record last use date:");
        console.log(error);
      }
      return;
    }
  }, [isOnline, loggedUser, token, tokenPayload]);

  useEffect(() => {
    
    (async () => {
      if (isMountedMigrationToken.current === false){
        migrateCookie();
        isMountedMigrationToken.current = true;
      }
      console.log(token)
      // Refresh V1 token to v2 token
      if(token){
        // V1 tokens don't have programId, as only single tenancy existed back then
        if(!tokenPayload.programId){
          // Redirect SUTTI V1 Program CASCADE to multi-tenant /cascade path
          // do it first time so users get to convert their access token to V2 and keep their data

          const fetchedRefreshToken = await handleServerError(dispatch(fetchRefreshToken({token})));
          if (fetchedRefreshToken.meta.requestStatus === "fulfilled") {
            setToken((fetchedRefreshToken.payload as User).token);
          }
          window.location.reload()          
          // if (window.location.hostname.endsWith('cascade.sutti.app') && !window.location.pathname.startsWith('/cascade/'))
          // {
          //   console.log("REDIRECTING")
          //   // history.push("/cascade/") 
          //   window.location.replace('/cascade')
          //   window.location.reload()
          // }
        }
      }
    })()
  }, [token, tokenPayload, loggedUser, dispatch, handleServerError, setToken, migrateCookie, history])

  useEffect(() => {
    if (token && tokenPayload.programId && tokenPayload.programId === program.id && isMountedToken.current === false) {
        setToken(token);
        isMountedToken.current = true;
      }
  }, [token, tokenPayload, program, removeToken, setToken, isMountedToken])

  useEffect(() => {
    updateLastAccess();
  }, [updateLastAccess]);

  useEffect(() => {
    //This is necessary to trigger initialization of speech api
    // for IOS platform
    enableAutoTTS(langue)
  }, [langue])

  const handleCloseSnackbar = () => {
    dispatch(setShowSuccessInfo(false))
  }

  const handleCloseSnackbarTransaction = () => {
    dispatch(setShowSuccessInfoTransaction(false))
  }




  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.app}>
        <Switch>
          <Redirect exact from={getProgramPath(program.name)} to={getLoginPath(program.name)} />
          <Route exact path={getCompatibilityCheckPath(program.name)} component={CompatibilityCheck} />
          <Route exact path={getMyTermsAndConditionsPath(program.name)} component={MyTermsAndConditions} />
          <Route exact path={getTermsAndConditionsPath(program.name)} component={TermsAndConditions} />
          <Route exact path={getTermsAndConditionsAgreementPath(program.name, courseSlug, chapterId)}
                 render={ routeProps => <Agreement courseSlug={courseSlug} chapterId={chapterId} {...routeProps} /> }/>
          {/* When the user has a token cookie and try to go to the login page, he is redirected to the my courses page */}
          {
              token && <Redirect exact from={getLoginPath(program.name)}
                                 to={ firstAccess ? getSplashPath(program.name) : getWelcomePath(program.name) }/>
          }
          <Route exact path={getLoginPath(program.name)} component={LoginPage} />
          {/* When the user has no token, he is redirected to the login page (except if he is already in the login page ofcourse)  */}
          {
              !token && <Redirect to={{ pathname: getLoginPath(program.name), state: { from: location, reason: "notLogged" } }} />
          }
          <Route exact path={getSplashPath(program.name)} component={SplashPage} />
          { token && tokenPayload.programId && <Route path={ getProgramPath(program.name) } component={Home} />}
        </Switch>

        <Dialog open={ openUpdate }
                onClose={ handleClose }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { language.updateContent }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={ handleUpdate } color="primary">
              { language.ok }
            </Button>
          </DialogActions>
        </Dialog>
      <PWAinstall variant="dialog"/>
      <PWAinstall variant="safari"/>
        <Snackbar open={showSuccessInfo}
                  message={language.dataSent}
                  onClose={handleCloseSnackbar} />
        <Snackbar open={showSuccessInfoTransaction}
                  message={language.dataSent}
                  onClose={handleCloseSnackbarTransaction} />
      </div>
    </MuiThemeProvider>
  );
}

export default App;

