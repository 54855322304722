import { FC, useEffect, useState } from 'react'
import { useToken } from 'cookies'
import { useProgram } from '../../program'
import { useHistory } from 'react-router-dom'
import { getProfileEditPath, getProfilePath, getSettingsPath } from 'routes'
import { useSelector, useDispatch } from 'react-redux'
import { AppThunkDispatch } from 'redux/store'
import { selectOnlineState } from 'pages/Impact/Impact.slice'
import {
    selectLoggedUser,
    selectLanguage,
    selectLanguageObject
} from 'pages/Login/LoginSlice'
import {
    FetchProfileUserData,
    selectError,
    getProfileState,
    selectIsLoading,
} from './ProfileSlice'

import { UserProfile } from 'types/userProfile'
import { handleErrorCodes } from 'utils/httpErrorCodes'
import { ErrorDetails } from 'types'
import { useServerErrorHandler } from 'hooks'
import countries from '../../resources/locales/countries.json'
import {
    HeaderContainer,
    Container,
    ErrorComponent,
    Offline,
    Title,
    Icon,
    Button,
    Snackbar,
    Speech,
    Loader
} from 'components'
import { Avatar, Typography } from '@material-ui/core'
import back from 'resources/icons/back.svg'
import cross from "resources/icons/cross.svg"
import useStyles from './Profile.styles'
import Map from 'components/Map/Map'


const ProfilePage: FC<{}> = () => {
    const program = useProgram()
    const { token } = useToken()
    const history = useHistory()
    const classes = useStyles()
    const handleServerError = useServerErrorHandler()

    const dispatch = useDispatch<AppThunkDispatch>()
    const language = useSelector(selectLanguageObject)
    const isOnline = useSelector(selectOnlineState)
    const loggedUser = useSelector(selectLoggedUser)
    const profileState = useSelector(getProfileState)
    const error = useSelector(selectError)
    const languageLocale = useSelector(selectLanguage)
    const isLoading = useSelector(selectIsLoading);
    const [snackbarState, setSnackbarState] = useState(true)
    const [errorDetails, setErrorDetails] = useState({} as ErrorDetails)



    const handleCloseSnackbarSlowConnection = () => {
        setSnackbarState(false)
    }

    const handleClose = () => {
        history.push(getSettingsPath(program.name))
    }

    useEffect(() => {
        if (isOnline && loggedUser && token) {
            handleServerError(dispatch(FetchProfileUserData({ token })))
        }
    }, [dispatch, handleServerError, isOnline, loggedUser, token])

    useEffect(() => {
        if (error && error.httpStatus !== 503) {
            setErrorDetails(handleErrorCodes(error, language.connectionError))
        }
    }, [error, language.connectionError])

    const renderAvatar = (imageUrl: string | undefined, title: string) => {
        return imageUrl
            ? <Avatar
                className={classes.avatar}
                src={imageUrl}
                alt={title}
            />
            : <Avatar className={classes.avatar}>
                {title ? title.toUpperCase() : ''}
            </Avatar>
    }

    const goToEditProfile = (profileData: UserProfile) => {
        history.push({
            pathname: getProfileEditPath(program.name),
            state: profileData
        })
    };

    const formatDate = (ISODate: string): string => {
        const dateObject = new Date(ISODate)
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" }
        if (ISODate !== undefined)
            return Intl.DateTimeFormat(languageLocale, options).format(dateObject)
        else
            return ''


    }

    const getCountryName = (countryLocale: string) => {
        if (countryLocale !== undefined) {
            const country = countries.filter((country) => country.code === countryLocale)
            return country[0].name
        }
        else
            return ''
    }

    //Convert the GPS coordinates from string to array of number
    const handleGPS = (gps: string) => {
        const gpsFormatted= gps.split(",").map(Number)
        return gpsFormatted
    };

    if (isOnline && isLoading) return <Loader/>


    return (
        errorDetails.message
      ? <ErrorComponent message={errorDetails.message} path={getProfilePath(program.name)} />
      :
        <Offline content={profileState}>
            <HeaderContainer className={classes.headerContainer}>
                <Icon
                    onClick={handleClose}
                    className={classes.icon}
                    imageUrl={back}
                />
                <Speech text={language.myProfile}>
                    <Title title={language.myProfile} />
                </Speech>
                <Icon onClick={handleClose} className={classes.icon} imageUrl={cross} />
            </HeaderContainer>

            <Container variant="contentWithFixedHeader">
                <div>
                    <div className={`${classes.profileHeader} ${classes.sectionContainer}`}>
                        <div className={classes.avatarContainer}>
                            {
                                renderAvatar(
                                    profileState?.photo,
                                    profileState ? profileState.lastName : ''
                                )
                            }
                        </div>

                        <div className={classes.nameAndPhone}>
                            <Speech text={profileState?.firstName as string + profileState?.lastName as string}>
                                <Typography
                                    className={classes.sectionTitle}
                                >
                                    {profileState?.firstName} {profileState?.lastName}
                                </Typography>
                            </Speech>
                            <Speech text={profileState?.phoneNumber as string}>
                                <Typography
                                    className={classes.phoneNumber}
                                >
                                    {profileState?.phoneNumber}
                                </Typography>
                            </Speech>
                            <Speech text={language.editProfile}>
                                <Button
                                    label={language.editProfile}
                                    onClick={() => profileState ? goToEditProfile(profileState) : null}
                                    className={classes.editButton}
                                />
                            </Speech>
                        </div>
                    </div>

                    <div className={classes.sectionContainer}>
                        <div className={classes.sectionTitleContainer}>
                            <Speech text={language.programInfos}>
                                <Typography
                                    className={classes.sectionTitle}
                                >
                                    {language.programInfos}
                                </Typography>
                            </Speech>
                        </div>

                        <div className={classes.formContainer}>
                            <div className={classes.formRow}>
                                <Speech text={language.lastSurveySub + formatDate(profileState?.lastSurveyDate as string)}>
                                    <div className={classes.inputLabel}>
                                        {language.lastSurveySub}
                                    </div>
                                </Speech>
                                <Speech text={language.lastSurveySub + formatDate(profileState?.lastSurveyDate as string)}>
                                    <div className={classes.input}>
                                        {profileState?.lastSurveyDate ? formatDate(profileState?.lastSurveyDate) : ''}
                                    </div>
                                </Speech>
                            </div>

                            <div className={classes.formRow}>
                                <Speech text={language.enrollmentDate + formatDate(profileState?.enrollmentDate as string)}>
                                    <div className={classes.inputLabel}>
                                        {language.enrollmentDate}
                                    </div>
                                </Speech>
                                <Speech text={language.enrollmentDate + formatDate(profileState?.enrollmentDate as string)}>
                                    <div className={classes.input}>
                                        {profileState?.enrollmentDate ? formatDate(profileState?.enrollmentDate) : ''}
                                    </div>
                                </Speech>
                            </div>

                            <div className={classes.formRow}>
                                <Speech text={language.firstLoginDate + formatDate(profileState?.firstAccessDate as string)}>
                                    <div className={classes.inputLabel}>
                                        {language.firstLoginDate}
                                    </div>
                                </Speech>
                                <Speech text={language.firstLoginDate + formatDate(profileState?.firstAccessDate as string)}>
                                    <div className={classes.input}>
                                        {profileState?.firstAccessDate ? formatDate(profileState?.firstAccessDate) : ''}
                                    </div>
                                </Speech>
                            </div>
                        </div>
                    </div>

                    <div className={classes.sectionContainer}>
                        <div className={classes.sectionTitleContainer}>
                            <Speech text={language.myFarm}>
                                <Typography
                                    className={classes.sectionTitle}
                                >
                                    {language.myFarm}
                                </Typography>
                            </Speech>
                        </div>

                        <div className={classes.formContainer}>
                            <div className={classes.formCol}>
                                <Speech text={language.address}>
                                    <div className={classes.inputLabel}>
                                        {language.address}
                                    </div>
                                </Speech>
                                <Speech text={profileState?.postalAddress as string}>
                                    <div className={classes.input}>
                                        {profileState?.postalAddress}
                                    </div>
                                </Speech>
                            </div>

                            <div className={classes.formCol}>
                                <Speech text={language.city}>
                                    <div className={classes.inputLabel}>
                                        {language.city}
                                    </div>
                                </Speech>
                                <Speech text={profileState?.city as string}>
                                    <div className={classes.input}>
                                        {profileState?.city}
                                    </div>
                                </Speech>
                            </div>

                            <div className={classes.formCol}>
                                <Speech text={language.country}>
                                    <div className={classes.inputLabel}>
                                        {language.country}
                                    </div>
                                </Speech>
                                <Speech text={getCountryName(profileState?.country as string)}>
                                    <div className={classes.input}>
                                        {profileState?.country ? getCountryName(profileState?.country) : ''}
                                    </div>
                                </Speech>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.formContainer}>
                            <div className={classes.formCol}>
                                <Speech text={language.GPS}>
                                <div className={classes.inputLabel}>
                                    {language.GPS}
                                </div>
                                </Speech>
                                <Speech text={profileState?.gps_coordinates as string}>
                                <div className={classes.input}>
                                    {profileState?.gps_coordinates}
                                </div>
                                </Speech>
                            </div>
                        </div>
                    </div>
                    {profileState?.gps_coordinates ? 
                    <Map gpsValues={handleGPS(profileState?.gps_coordinates)} />
                        :
                    null}      
                </div>
                {
                    errorDetails.slowConnexionError ?
                        <Snackbar
                            open={snackbarState}
                            message={language.slowConnection}
                            onClose={handleCloseSnackbarSlowConnection}
                        />
                        : null
                }
            </Container>
        </Offline>
    )
}
export default ProfilePage