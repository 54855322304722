import React, { useState, useEffect, useCallback } from "react";
import { useToken } from "cookies";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  getAddCoursePath,
  getAppTutorialPath,
  getChapterPath,
  getCopyrightPath,
  getCoursePath,
  getCourseSummaryPath,
  getSettingsPath,
  getImpactPath,
  getIndexPath,
  getMessagesPath,
  getMyCoursesPath,
  getProfilePath,
  getProfileEditPath,
  getProgramPath,
  getServicesPath,
  getSurveyPath,
  getTransactionPath,
  getTransactionsPath,
  fillsSurvey,
  surveyLinkParam,
  transactionsCollectIdParam,
  getSingleMessagePath,
  getWelcomePath,
} from 'routes'
import AppTutorials from "pages/AppTutorials";
import AddCoursePage from "./AddCourse";
import ChapterPage from "./Chapter";
import CoursePage from "./Course";
import ImpactPage from "./Impact";
import MyCoursesPage from "./MyCourses";
import SurveyPage from "./Survey";
import TransactionsPage from "./Transactions";
import TransactionPage from "./Transactions/Transaction";
import Settings from "./FeaturesHub/Settings/Settings";
import Services from "./Services/Services";
import ProfilePage from "./Profile";
import ProfileEditPage from "./Profile/edit";
import CopyrightPage from "./Copyright/Copyright";
import CourseSummaryPage from "./CourseSummary";
import { useGetChapterParams, useServerErrorHandler, useGetSurveyParams, useQuery, useGetMessagesParams } from "hooks";
import { putService } from '../api';
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "redux/store";
import { selectLanguageObject, selectLoggedUser } from "./Login/LoginSlice";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import {
  fetchNotifications,
  getNotifications,
  removeNotifications
} from "components/Messages/NotificationPopUp/NotificationPopUp.slice";
import { sendOptedInState } from "pages/Profile/ProfileSlice";
import { checkUserPermission, generateDummyNotification } from "../utils/registerOptin";
import { PushNotificationsInfos, UserPermissionAnswer } from '../types/userProfile';
import { Notification } from "types/notifications";
import { NotificationsLevels, NotificationTypes } from "constants/notifications";
import { ErrorComponent, NotificationPopUp } from "components";
import useStyles from "./index_Styles";
import { useProgram } from '../program'
import Messages from './Messages'
import {  pullNotificationsInterval } from "api/config";
import { fillTemplateString } from '../utils/utils'
import Welcome from "./Welcome/Welcome";

//This page handle the architecture of the routes
const Home: React.FC<{}> = () => {
  const program = useProgram();

  const classes = useStyles();
  const { pathname } = useLocation();
  const query = useQuery()
  const history = useHistory()
  const { token } = useToken()

  const loggedUser = useSelector(selectLoggedUser);
  const isOnline = useSelector(selectOnlineState);
  const language:any = useSelector(selectLanguageObject);
  const { notifications } = useSelector(getNotifications);

  const dispatch = useDispatch<AppThunkDispatch>();
  const handleServerError = useServerErrorHandler();

  const { courseSlug, chapterId } = useGetChapterParams();
  const { collectId, questionId, surveyId } = useGetSurveyParams();
  const { messageId } = useGetMessagesParams();

  const pullMsInterval = parseInt(pullNotificationsInterval ?? '');
  const [notificationToShow, setNotificationToShow] = useState<Notification>();
  const [showNotification, setShowNotification] = useState(false);

  const handleOptedIn = useCallback((optIn: PushNotificationsInfos) =>
    { if (loggedUser) dispatch(sendOptedInState(token, optIn)) }
  , [dispatch, loggedUser, token])
    

  const getUserPermission = useCallback(async () => {
    const userPermission: UserPermissionAnswer = await checkUserPermission()
    if (loggedUser) {
      if(!userPermission.alreadyAnswered && !!userPermission.success) {
        if(userPermission.denied) {
          const optInValues = {
            optedIn: 0,
            pushInfos: null
          }
          handleOptedIn(optInValues)
        } else if(userPermission.success && !!userPermission.pushNotificationsInfos) {
          handleOptedIn(userPermission.pushNotificationsInfos)
  
          setTimeout(() => {
            const title = fillTemplateString(language.dummyNotificationTitle, {programName: program.name}),
                  body = language.dummyNotificationBody
            generateDummyNotification(title, body)
          }, 5000)
        }
      }
    }
   
  }, [handleOptedIn, language.dummyNotificationBody, language.dummyNotificationTitle, loggedUser, program.name])

  useEffect(() => {
    const hasFillsSurvey = pathname.split("/").includes(fillsSurvey)
    const collectId = query.get(surveyLinkParam)
    const isSurveyLink = collectId
      && collectId.toString() !== transactionsCollectIdParam
    if (hasFillsSurvey) {
      isSurveyLink
        ? history.push(getImpactPath(program.name, collectId!))
        : history.push(getTransactionsPath(program.name, transactionsCollectIdParam))
    }

  }, [program, pathname, query, history])

  useEffect(() => {
    if(token) {
      getUserPermission()
    }
  }, [token, getUserPermission])

  // execute notifications fetching immediatly the 1st time, then every 2mn
  useEffect(() => {
    if (isOnline && loggedUser && token) {
      handleServerError(dispatch(fetchNotifications({token})));
    }
  }, [isOnline, loggedUser, token, handleServerError, dispatch])

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (isOnline && loggedUser && token) {
        handleServerError(dispatch(fetchNotifications({token})));
      }
    }, pullMsInterval);
    return () => clearInterval(interval);
  }, [isOnline, loggedUser, token, handleServerError, dispatch, pullMsInterval]);

  const setNotificationsToRead = useCallback(async(notifications: Array<Notification>) => {
    const url = 'notifications';
    try {
      await (putService(token, url, notifications));
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const sortNotificationsByHigherLevel = useCallback((notifications: Array<Notification>) =>
    [...notifications].sort((a, b) =>
      Object.values(NotificationsLevels).indexOf(a.level) - Object.values(NotificationsLevels).indexOf(b.level)
    ), []);

  useEffect(() => {
    if(notifications.length > 0) {
      const priceChangeNotifications = notifications.filter(notification => notification.type === NotificationTypes.priceChange);
      const simpleNotification = notifications.filter(notification => notification.type === NotificationTypes.simpleMessage);

      if(priceChangeNotifications.length > 0) {
        const notificationsByLevel = sortNotificationsByHigherLevel(priceChangeNotifications);
        
        setNotificationToShow(notificationsByLevel[0]);
        if (window.location.pathname !== getSingleMessagePath(program.name, notificationsByLevel[0].id.toString())) setShowNotification(true) ;
        setNotificationsToRead(notifications);
      }

      if(simpleNotification.length > 0) {
        setNotificationToShow(simpleNotification[0]);
        if (window.location.pathname !== getSingleMessagePath(program.name, simpleNotification[0].id.toString())) setShowNotification(true);
        setNotificationsToRead(simpleNotification);
      }

    }
  }, [notifications, sortNotificationsByHigherLevel, setNotificationsToRead, program.name]);

  const removeNotificationsState = () => {
    setShowNotification(!showNotification)
    dispatch(removeNotifications());
  }



  return (
    <main className={classes.main}>
      <Switch>
        <Redirect exact
                  from={getProgramPath(program.name)}
                  to={getWelcomePath(program.name)} />
        <Route exact
               path={getIndexPath(program.name)}
               component={MyCoursesPage} />
        <Route exact
               path={getWelcomePath(program.name)}
               component={Welcome} />
        <Route exact
               path={getMyCoursesPath(program.name)}
               component={MyCoursesPage} />
        <Route exact
               path={getImpactPath(program.name)}
               component={ImpactPage} />
        <Route exact
               path={getTransactionsPath(program.name)}
               component={TransactionsPage} />
        <Route exact
               path={getSettingsPath(program.name)}
               component={Settings} />
        <Route exact
               path={getAppTutorialPath(program.name)}
               component={AppTutorials} />
        <Route exact
               path={getMessagesPath(program.name, notificationToShow)}
               render={() => <Messages message={notificationToShow} />} />
        <Route exact
               path={getSingleMessagePath(program.name, messageId)}
               render={() => <Messages messageId={messageId} />} />
        <Route exact
               path={getServicesPath(program.name)}
               component={Services}  />
        <Route exact
               path={getProfilePath(program.name)}
               component={ProfilePage} />
        <Route exact
              path={getProfileEditPath(program.name)} component={ProfileEditPage} />
        <Route exact path={getCopyrightPath(program.name)}
               component={CopyrightPage} />
        <Route exact
               path={getTransactionPath(program.name, transactionsCollectIdParam, surveyId, questionId)}
               component={TransactionPage} />
        <Route exact
               path={getSurveyPath(program.name, collectId, surveyId, questionId)}
               render={() => <SurveyPage collectId={collectId} />} />
        <Route exact
               path={getCourseSummaryPath(program.name)}
               component={CourseSummaryPage} />
        <Route exact
               path={getCoursePath(program.name)}
               component={CoursePage} />
        <Route exact
               path={getChapterPath(program.name, courseSlug, chapterId)}
               render={(routeProps) => <ChapterPage courseSlug={courseSlug} chapterId={chapterId} {...routeProps} />} />
        <Route exact
               path={getAddCoursePath(program.name)}
               component={AddCoursePage} />
        <Route render={() => <ErrorComponent message={language.pageNotFound}/>} />
      </Switch>


      {
        showNotification && notificationToShow &&
        <NotificationPopUp message={notificationToShow} onClose={() => removeNotificationsState()} />
      }
    </main>
  )
}

export default Home

