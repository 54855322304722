import { Button as MUIButton } from "@material-ui/core"
import clsx from "clsx"
import { Speech } from "components"
import { useSmallScreen } from "hooks"
import React from "react"
import useStyles from "./Button.styles"

export interface ButtonProps {
    label: string
    onClick: () => void
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    disabled?: boolean,
    className?: string
    rootClassName?: string
    href?: string
}

//multi purpose button
const Button: React.FC<ButtonProps> = (props) => {
    const { label, className, disabled, onClick,
        rootClassName, href, ...restProps } = props
    const classes = useStyles()
    const isSmallScreen = useSmallScreen()

    const buttonClass = isSmallScreen
        ? clsx(classes.button, classes.smallButton)
        : classes.button

    return (
        <Speech text={label}
            onClick={disabled ? undefined : onClick}
            className={disabled
                ? clsx(classes.disabled, buttonClass)
                : clsx(classes.speech, rootClassName)}>
            <MUIButton
                variant={"contained"}
                href={href}
                color="primary"
                className={disabled
                    ? clsx(classes.disabled, buttonClass)
                    : clsx(buttonClass, className)}
                disableRipple
                disabled={disabled}
                {...restProps}>
                {label}
            </MUIButton>
        </Speech>
    )
}

export default Button