import { useLongPress } from "hooks"
import { selectLanguage } from "pages/Login/LoginSlice"
import React from "react"
import { useSelector } from "react-redux"
import clsx from "clsx"
import useStyles from "./Speech_Styles"

interface SpeechProps {
    children: React.ReactNode
    text: string
    className?: string
    onClick?: ((notification: Notification) => void) | (() => void);
}
//This component handle the text to speech 
const Speech: React.FC<SpeechProps> = (props) => {
    const { children, text, className, onClick } = props
    const classes = useStyles()
    const language = useSelector(selectLanguage)
    const callback: (() => void) | ((notification: Notification) => void) = onClick ? onClick : () => { };
    
    // isSpeaking : boolean for Microsoft TTS (tamil and singhalese)
    // speaking : boolean for react TTS (other languages)
    const { speaking, isSpeaking,  ...longPress } = useLongPress(language, text, callback)

    // true when longpressing
    const activeClass = (speaking || isSpeaking) ? classes.root : undefined

    // true when longpressing
    const key = `${text}-${(speaking || isSpeaking) ? "speaking" : "not-speaking"}`

    return (
        <div key={key} className={clsx(activeClass, className)} {...longPress}>
            {children}
        </div>
    )
}

export default Speech