import { useMediaQuery } from "@material-ui/core";
import { useToken } from "cookies";
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useSpeechSynthesis } from 'react-speech-kit';
import { getLoginPath } from "routes";
import { Language } from "types";
import { ErrorType } from "types/common";
import { speechSynthMsKey, speechSynthMsServer } from 'api/config';
import { useProgram } from '../program'

//This page handle hooks : the voice synthesis and getting param between component
/**
 * Returns a speech synthesis voice
 * @param {Language} language language key as that of app env config
 * languages object
 */
export const useGetVoice = (language: Language) => {
    const [voices, setVoices] = useState<Array<SpeechSynthesisVoice>>([]);

    const processVoices = (voiceOptions: Array<SpeechSynthesisVoice>) => {
        console.log(voiceOptions)
        setVoices(voiceOptions);
    };

    const getVoices = useCallback(() => {
        // Firefox seems to have voices upfront and never calls the
        // voiceschanged event
        let voiceOptions = window.speechSynthesis.getVoices();
        if (voiceOptions.length > 0) {
            processVoices(voiceOptions);
            return;
        }

        window.speechSynthesis.onvoiceschanged = (event: any) => {
            voiceOptions = event.target.getVoices();
            if (voiceOptions.length > 0) {
                processVoices(voiceOptions);
                return;
            }
        };
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined' && window.speechSynthesis) {
            getVoices();
        }
    }, [getVoices]);

    const findVoice = (voices: Array<SpeechSynthesisVoice>, languages: Array<Language>): SpeechSynthesisVoice | undefined => {
        console.log("voices",voices)
        console.log("languages",languages)
        let  transformedLanguages : any
        let finalvoice
        transformedLanguages = languages.map(lang => lang.toLowerCase())
        finalvoice = voices.find(voice => transformedLanguages.includes(voice.lang.toLowerCase()))
        languages.forEach(lang => console.log(lang.toLowerCase().replace("-", "_"))) 
        if (finalvoice === undefined) {
            transformedLanguages = languages.map(lang => lang.toLowerCase().replace("-", "_"))
            console.log("new transformed languages", transformedLanguages)
            finalvoice = voices.find(voice => transformedLanguages.includes(voice.lang.toLowerCase()))
            console.log("finalvoice Android",finalvoice)
        }
        console.log("finalvoice",finalvoice)
        return finalvoice;
    }
    

    /**
     * This method will return an english synthesis
     * It was necessary because voices list is different for different navigators
     */
    const getEnglishVoice = (): SpeechSynthesisVoice => {
        const ukVoice = findVoice(voices, ["en-GB", "en_GB"])
        if (ukVoice) {
            return ukVoice
        }
        const usVoice = findVoice(voices, ["en-GB", "en_US"])

        if (usVoice) {
            return usVoice
        }

        return findVoice(voices, ["en"])!
    }

    // @ts-ignore: do not remove this single-line comment that tells the compiler to ignore a possible TypeScript error if the program does not support English language at att
    if (language.startsWith("en")) {
        return getEnglishVoice()
    }

    if (voices.length === 0) getVoices();

    return findVoice(voices, [language])
}

 
/**
 * Hook use for long press event
 * @param text for speechSynthesis
 * @param onClick default onCLick action
 * @param shouldPreventDefault To prevent default action by default
 *
 * To use this hook just import and use as follows
 * const longPress = useLongPress()
 * <Component {...longPress}/>
 */

export const useLongPress = (
    language: Language,
    text: string,
    onClick: () => void,
    shouldPreventDefault: boolean = true) => {

    const timeout: any = useRef();
    const target: any = useRef();
    const { speak, speaking } = useSpeechSynthesis();
    const voice = useGetVoice(language)
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const [touchMove, setTouchMove] = useState(false)
    // isSpeaking used to not call Microsoft Text-To-Speech (TTS) if already speaking
    const [isSpeaking, setIsSpeaking] = useState(false)
    const userAgent = navigator.userAgent || navigator.vendor;
    const handleLong = useCallback(async () => {
        if (((language === "si-LK") && !(/android/i.test(userAgent))) || (language === "ta-LK"))  {
            // If TTS is already speaking, do not speak on top
            if (!isSpeaking){
                await synthesizeSpeech(text, language)
            }
        } else {
            const speech = new SpeechSynthesisUtterance(text);
            // Android required
            console.log("speech.lang",voice?.lang ?? "en-US")
            speech.lang = voice?.lang ?? "en-US";
            // IOS required
            console.log("speech.voice",speech.voice)
            speech.voice = voice;
            console.log("speech",speech)
            speech.volume =1 
            speech.rate = 1
            speech.pitch = 1
            await speak( speech )}
    }, [speak,isSpeaking, text, voice, language, userAgent])


    async function synthesizeSpeech(txt: string, language:string) {

        console.log("here")
        let sdk = require("microsoft-cognitiveservices-speech-sdk");
        const speechConfig = await sdk.SpeechConfig.fromSubscription(speechSynthMsKey, speechSynthMsServer);
        speechConfig.speechSynthesisLanguage =  language; // e.g. "de-DE"
            // Change default voice 
            if(language === "si-LK"){
                speechConfig.speechSynthesisVoiceName = "si-LK-ThiliniNeural";
            }

        const player = new sdk.SpeakerAudioDestination()
            
        // When audio start, future TTS will not be played on top of it
        player.onAudioStart = function () {
            setIsSpeaking(true)
          };

        // When audio end, future TTS can be played
        player.onAudioEnd = function () {
            setIsSpeaking(false)
          };
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, 
            sdk.AudioConfig.fromSpeakerOutput(player));
        await synthesizer.speakTextAsync(txt, (result:boolean) => {
            if (result) {
                synthesizer.close();
                return result.valueOf;
            }
        },
            (error:boolean) => {
                console.log(error);
                synthesizer.close();
            }
        );
    }
    

    const start = useCallback((event: any) => {
        if (shouldPreventDefault && event.target) {
            event.target.addEventListener("touchend", preventDefault, {
                passive: false
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(() => {
            handleLong()
            setLongPressTriggered(true);
        }, 1000);
    }, [handleLong, shouldPreventDefault]);

    const clear = useCallback((event: any, shouldTriggerClick: boolean = true) => {
        timeout.current && clearTimeout(timeout.current);
        shouldTriggerClick && !longPressTriggered && !touchMove && onClick();
        setLongPressTriggered(false)
        setTouchMove(false)
        if (shouldPreventDefault && target.current) {
            target.current.removeEventListener("touchend", preventDefault);
        }
    }, [shouldPreventDefault, longPressTriggered, onClick, touchMove]);

    const handleTouchMove = useCallback((e: React.TouchEvent<Element>) => {
        e.preventDefault()
        setTouchMove(true)
    }, [])

    return {
        speaking: speaking || undefined,
        isSpeaking :isSpeaking || undefined,
        onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => start(e),
        onTouchStart: (e: React.TouchEvent<Element>) => start(e),
        onMouseUp: (e: React.MouseEvent<Element, MouseEvent>) => clear(e),
        onMouseLeave: (e: React.MouseEvent<Element, MouseEvent>) => clear(e, false),
        onTouchEnd: (e: React.TouchEvent<Element>) => clear(e),
        onContextMenu: (e: any) => e.preventDefault(),
        onTouchMove: (e: React.TouchEvent<Element>) => handleTouchMove(e)
    };
};

const isTouchEvent = (event: React.TouchEvent<Element>) => {
    return "touches" in event;
};

const preventDefault = (event: React.TouchEvent<Element>) => {
    if (!isTouchEvent(event)) return;

    if ((event).touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};

/**
 * This hook is used get chapter params, which are
 * used for routing and api calls
 */

export const useGetChapterParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/chapters")) {
        const splittedPath = location.pathname.split("/")
        return {
            courseSlug: splittedPath[3],
            chapterId: splittedPath[5]
        }
    }

    return {
        courseSlug: ":slug",
        chapterId: ":chapterId"
    }
}

/**
 * This hook is used get survey params, which are
 * used for routing and api calls
 */

export const useGetSurveyParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/surveys")) {
        const splittedPath = location.pathname.split("/")
        return {
            collectId: splittedPath[3],
            surveyId: splittedPath[5],
            questionId: splittedPath[7]
        }
    }

    return {
        collectId: ":collectId",
        surveyId: ":surveyId",
        questionId: ":questionId"
    }
}

/**
 * This hook is used get survey params, which are
 * used for routing and api calls
 */

export const useGetMessagesParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/messages")) {
        const splittedPath = location.pathname.split("/")
        return {
            messageId: splittedPath[3]
        }
    }

    return {
        messageId: ":messageId"
    }
}

type ServerErrorHandler = <T> (promise: Promise<T>) => Promise<T>

/**
 * Hook returning a function that handles errors thrown by sutti
 * server when a service is called.
 */
export const useServerErrorHandler = (): ServerErrorHandler => {
    const program = useProgram()
    const history = useHistory()
    const { removeToken } = useToken()

    return useCallback(<T>(promise: Promise<T>) =>
        promise.catch((httpError: ErrorType) => {
            const { httpStatus } = httpError
            // case of expired client session id
            if (httpStatus === 401) {
                const location = history.location
                 // remove V1 and V2 cookies
                 removeToken()
                history.push(
                    getLoginPath(program.name),
                    {
                        from: location,
                        reason: "expired"
                    }
                )
            }

            return Promise.reject(httpError)
        }),
        [program, history, removeToken])
}

/**
 * Hook that provide the search part of the url.
 * "Search part" means everything after '?' in the url.
 */
export const useQuery = (): URLSearchParams =>
    new URLSearchParams(useLocation().search.substring(1))

/**
 * MEDIA QUERIES FOR RESPONSIVE DESIGN
 */

/**
 * Example -> Returns true if width is less than 300px
 * else false
 */
export const useSmallScreen = () => {
    const isSmallScreen = useMediaQuery('(max-width:360px)')
    return isSmallScreen
}

export const useMediumWidthScreen = () => {
    const  isMediumScreen = useMediaQuery('(max-width:450px)')
    return isMediumScreen
}

export const useH360Screen = () => {
    const isSmallScreen = useMediaQuery('(max-height:360px)')
    return isSmallScreen
}

export const useMediumScreen = () => {
    const isMediumScreen = useMediaQuery('(min-height:360px)'&&'(max-height:640px)')
    return isMediumScreen
}
