import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { style } from "style";

export default makeStyles((theme: Theme) => {

    return createStyles({
       instructions: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection:"row",
        alignItems: "center",
        columnGap: "0.5rem",
        paddingBottom: "0.5rem",
        "& > *": {

            fontSize: "1rem",
            width:"50%"
        }
        
       },
       button: {
        background: style.palette.white,
        marginTop: "1.5rem",
        color: theme.palette.primary.main,
        display: "flex-start",
        justifyContent: "space-between",
        maxWidth: (props: StyleProps) => props.maxWidth,
        width: (props: StyleProps) => props.width,
        height: (props: StyleProps) => props.height
      },
      smallButton: {
        background: style.palette.white,
        color: theme.palette.primary.main,
        left: "auto",
        right:"auto",
        fontSize: "0.70rem",
        display: "flex-start",
        justifyContent: "space-between",
        maxWidth: (props: StyleProps) => props.maxWidth,
        width: (props: StyleProps) => props.width,
        height: (props: StyleProps) => props.height
      },
      dialogButton:{
        height : "100%",
        color: theme.palette.secondary.light,
        padding : "0.4em",
        borderWidth: "1px",
        border : "solid",
        width:"48px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
      dialogText: {
        fontSize: "0.70rem",
        color: theme.palette.secondary.light,
        width: "100%",
        whiteSpace: "pre-line",
        
      },
      smallDialogText: {
        fontSize: "0.6rem",
        color: theme.palette.secondary.light,
        whiteSpace: "pre-line",
        wordBreak: "break-word"
      },
      dialog:{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        wordBreak: "break-word"
      },
      dialogInstructions: {
        display: "flex-start",
        alignItems: "center",
        textAlign: 'center', 
      },
      dialogContent:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding:"1.5em"
      }
    })
})