import { Text } from 'components'
import React from 'react'

export interface QuestionProps {
    title: string
    question: string
}

const Question: React.FC<QuestionProps> = ({ title, question }) => {
    return (
        <div>
            <Text variant="title">
                {title}
            </Text>
            <Text >
                {question}
            </Text>
        </div>
    )
}

export default Question
